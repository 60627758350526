<template>
	<div class="f1 apps-page w-full dF fC f1 px-4">
		<div class="dF aC">
			<div class="dF aC f1">
				<h3>Domains</h3>
			</div>
		</div>
		<a-card :bodyStyle="{ padding: 0 }" class="my-3">
			<div>
				<a-table :rowKey="(e) => e.id" :columns="columns" :dataSource="domains" :loading="loading" :pagination="{
					current: currentPage,
					total: totalDomains,
					pageSize: pageSize,
				}" @change="handleChange">
					<div slot="name" slot-scope="domain">
						{{ domain.name }}
					</div>
					<div slot="state" slot-scope="domain">
						<span v-if="domain.state === 'unverified'" class="project-button" style="background-color: #ffc107">
							Unverified
						</span>
						<span v-else-if="domain.state === 'active'" class="project-button"
							style="background-color: #28c791">
							Active
						</span>
						<span v-else class="project-button">
							{{ domain.state }}
						</span>
					</div>
					<div slot="projectName" slot-scope="domain">
						{{ domain.instance && domain.instance.name }}
					</div>
					<div slot="createdAt" slot-scope="domain" style="color: var(--med-gray)">
						{{ getDate(domain.createdAt) }}
					</div>
					<div slot="updatedAt" slot-scope="domain" style="color: var(--med-gray)">
						{{ getDate(domain.updatedAt) }}
					</div>
					<div slot="actions" slot-scope="domain" class="dF aC" style="gap: 20px">
						<a-button @click="viewDetails(domain)">View Report</a-button>
					</div>
				</a-table>
			</div>
		</a-card>
	</div>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
	data() {
		return {
			sortedInfo: {
				key: "updatedAt",
				order: "DESC",
			},
			domains: [],
			loading: false,
			currentPage: 1,
			totalDomains: 0,
			pageSize: 10,
		};
	},
	computed: {
		search() {
			return this.$store.state.searchFilter.toLowerCase();
		},

		columns() {
			return [
				{
					title: "Domain Name",
					key: "name",
					scopedSlots: { customRender: "name" },
					sorter: true,
				},
				{
					title: "State",
					key: "state",
					scopedSlots: { customRender: "state" },
					sorter: true,
				},
				{
					title: "Project Name",
					key: "instance.name",
					scopedSlots: { customRender: "projectName" },
					sorter: true,
				},
				{
					title: "Created Date",
					key: "createdAt",
					scopedSlots: { customRender: "createdAt" },
					sorter: true,
				},
				{
					title: "Updated Date",
					key: "updatedAt",
					scopedSlots: { customRender: "updatedAt" },
					sorter: true,
				},
				{
					title: "",
					key: "actions",
					scopedSlots: { customRender: "actions" },
				},
			];
		},
	},
	watch: {
		search() {
			this.searchDebounce();
		},
	},
	created() {
		this.getDomainsCount();
		this.getDomains();
	},

	methods: {
		...mapMutations(["UPDATE_SEARCH", "SET_DOMAIN"]),

		viewDetails(domain) {
			this.SET_DOMAIN(domain)
			this.$router.push(`/domains/${domain.id}`)
		},

		searchDebounce: _.debounce(function () {
			this.getDomainsCount();
			this.getDomains();
		}, 1000),

		async getDomainsCount() {
			try {
				let searchQuery = "";
				if (this.search) {
					searchQuery = `?_q=${this.search}`;
				}
				let { data } = await this.$api.get(
					`/sending-domains/count${searchQuery}`
				);
				this.totalDomains = data;
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching domains count. Please try again."))
				}
			}
		},

		async getDomains() {
			this.loading = true;
			try {
				let start = (this.currentPage - 1) * this.pageSize;
				let searchQuery = "";
				if (this.search) {
					searchQuery = `&_q=${this.search}`;
				}
				let { data } = await this.$api.get(
					`/sending-domains?_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${searchQuery}`
				);
				this.domains = data;
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching domains list. Please try again."))
				}
			}
			this.loading = false;
		},

		getDate(date) {
			return moment(date).format("D/M/YYYY");
		},

		handleChange(pagination, filter, sorter) {
			if (pagination) {
				this.currentPage = pagination.current;
			}
			if (sorter && sorter.column) {
				this.sortedInfo = {
					key: sorter.columnKey,
					order: sorter.order === "descend" ? "DESC" : "ASC",
				};
			}
			this.getDomains();
		}
	},

	beforeDestroy() {
		this.UPDATE_SEARCH("");
	},
};
</script>


<style lang="scss" scoped>
.project-button {
	color: #fff;
	border-radius: 4px;
	font-size: 14px;
	text-align: center;
	padding: 2px 8px;
}
</style>
